export const mediaStati = [
  { id: "draft", name: "Draft" },
  { id: "active", name: "Active" },
  { id: "deleted", name: "Deleted" },
];

export const mediaReviewStati = [
  { id: "not_reviewed", name: "Not Reviewed" },
  { id: "in_review", name: "In Review" },
  { id: "passed", name: "Passed" },
  { id: "ok", name: "Ok" },
  { id: "good", name: "Good" },
  { id: "great", name: "Great" },
  { id: "concern", name: "Concern" },
  { id: "failed", name: "Failed" },
];

export const mediaTypes = [
  { id: "meditation", name: "Meditation" },
  { id: "music", name: "Music" },
  { id: "session", name: "Session" },
  { id: "sound", name: "Sound" },
];

export const mediaDurations = [
  { id: 5, name: "5 minutes" },
  { id: 10, name: "10 minutes" },
  { id: 15, name: "15 minutes" },
  { id: 30, name: "30 minutes" },
  { id: 50, name: "50 minutes" },
];

export const mediaTags = [
  { id: "instrumental", name: "instrumental" },
  { id: "5-minute", name: "5-minute" },
  { id: "less-guidance", name: "less-guidance" },
  { id: "3-part-breath", name: "3-part-breath" },
  { id: "box-breathing", name: "box-breathing" },
  { id: "baby-kid", name: "baby-kid" },
  { id: "water", name: "water" },
  { id: "noise", name: "noise" },
  { id: "wind", name: "wind" },
  { id: "fire", name: "fire" },
  { id: "forest", name: "forest" },
  { id: "other", name: "other" },
  { id: "classical", name: "Classical" },
  { id: "binaural-beats", name: "Binaural Beats" },
];

export const userStati = [
  { id: "initial", name: "Initial" },
  { id: "deleted", name: "Deleted" },
  { id: "blocked", name: "Blocked" },
  { id: "stripesub", name: "StripeSub" },
  { id: "b2b_loaded", name: "b2b_loaded" },
];

export const staffStati = [
  { id: "", name: "None" },
  { id: "staff", name: "Staff" },
];

export const teachers = [
  { id: "bri", name: "Bri" },
  { id: "dana", name: "Dana" },
  { id: "ellie", name: "Ellie" },
  { id: "kevin", name: "Kevin" },
  { id: "liam", name: "Liam" },
  { id: "luigi", name: "Luigi" },
  { id: "maddy", name: "Maddy" },
  { id: "miya", name: "Miya" },
  { id: "mk", name: "MK" },
];

export const groupChartTypes = [
  // { id: "sleep_duration_area", name: "Sleep Duration Area" },
  { id: "sleep_duration", name: "Sleep Duration Area" },
  { id: "sleep_duration_bar", name: "Sleep Duration Bar" },
  { id: "wake_up_times_area", name: "Wake Up Times Area" },
  { id: "fall_asleep_area", name: "Fall Asleep Area" },
  { id: "daytime_functioning", name: "Daytime Functioning" },
  { id: "mood", name: "Mood" },
];

// ONLY KEEP CURRENT ONES IN HER!
export const featureFlags = [
  { id: "challenges", name: "Challenges" },
  { id: "test123", name: "Test 123" },
];

export const emailFromIDs = [
  { id: "warren", name: "Warren" },
  { id: "ali", name: "Ali" },
  { id: "rey", name: "Rey" },
  { id: "heythere", name: "Hey There" },
];

export const emailTypes = [
  { id: "ptc_hero1_2025_01_08", name: "Postmark PTC Hero1 (jan 8, 2025)" },
  { id: "ptc_hero2_2025_01_21", name: "Postmark PTC Hero2 (jan 21, 2025)" },
  { id: "ptc_hero3_2025_02_04", name: "Postmark PTC Hero3 (feb 3, 2025)" },
  {
    id: "ptc_hero4_2025_02_18",
    name: "Postmark PTC Hero4 (feb 18, 2025) (user didnt use app)(variant 0)",
  },
  // Variant 1 included in the id
  {
    id: "ptc_hero4_2025_02_18:1",
    name: "Postmark PTC Hero4 (feb 18, 2025) (user DID use app)(variant 1)",
  },

  {
    id: "ptc_hero5_2025_03_04",
    name: "Postmark PTC Hero5 (mar 4, 2025)",
  },

  {
    id: "ptc_hero6_2025_03_18",
    name: "Postmark PTC Hero6 (mar 18, 2025)",
  },

  {
    id: "ptc_reengage1_2025_02_18",
    name: "Postmark PTC Reengage1 (feb 18, 2025)",
  },
  {
    id: "ptc_new_hire1_2025_01_21",
    name: "Postmark PTC New Hire 1 (jan 20, 2025)",
  },
  {
    id: "ptc_post_signup1_2025_01_21",
    name: "Postmark PTC Post Signup 1 (jan 20, 2025)",
  },
  {
    id: "ptc_post_signup2_2025_01_30",
    name: "Postmark PTC Post Signup 2 (jan 30, 2025)",
  },
  {
    id: "ptc_post_signup3_2025_02_11",
    name: "Postmark PTC Post Signup 3 (feb 11, 2025)",
  },

  // { id: "otp", name: "OTP" },
  // { id: "generic", name: "Generic (type subj+body)" },
];

export const mailMsgLinkTypes = [
  { id: "pixel", name: "Pixel" },
  { id: "image", name: "Image" },
  { id: "click", name: "Click" },
];

// Difficulties should be beginner, intermediate, advanced
export const difficulties = [
  { id: "beginner", name: "Beginner" },
  { id: "intermediate", name: "Intermediate" },
  { id: "advanced", name: "Advanced" },
];
